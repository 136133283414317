<template>
    <div>
         404，页面找不到
        <router-link replace to="/">返回首页</router-link>
    </div>
</template>
<script>
export default {
    name:'404'
}
</script>